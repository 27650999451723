import { FaBoxOpen, FaFileAlt } from 'react-icons/fa';
import { GiMailbox } from 'react-icons/gi';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BuildIcon from '@material-ui/icons/Build';
import HomeIcon from '@material-ui/icons/Home';

import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import BadgeIcon from '@mui/icons-material/Badge';
import CategoryIcon from '@mui/icons-material/Category';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PaymentsIcon from '@mui/icons-material/Payments';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SavingsIcon from '@mui/icons-material/Savings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WorkIcon from '@mui/icons-material/Work';
export const menu = [
  { id: 'home', path: '/home', label: 'Ínicio', icon: <HomeIcon />, roles: 1 },
  {
    id: 'administration',
    label: 'Administração',
    icon: <SupervisorAccountIcon size='18' />,
    roles: 20,
    items: [
      {
        path: '/closures',
        label: 'Fechamentos',
        icon: <SavingsIcon size='18' />,
        roles: 114,
      },
    ],
  },
  {
    id: 'cashier',
    label: 'Caixa',
    icon: <AttachMoneyIcon />,
    roles: 3,
    items: [],
  },
  {
    id: 'collaborators',
    label: 'Colaboradores',
    icon: <AttachMoneyIcon />,
    roles: 16,
    items: [
      {
        path: '/order-product',
        label: 'Encomendar aparelho',
        icon: <LocalGroceryStoreIcon />,
        roles: 113,
      },
    ],
  },
  {
    id: 'access-control',
    label: 'Controle de Acesso',
    icon: <SupervisorAccountIcon />,
    roles: 14,
    items: [
      {
        path: '/create-role',
        label: 'Novo Grupo de Usuário',
        icon: <GroupAddIcon />,
        roles: 100,
      },
      {
        path: '/list-profiles',
        label: 'Perfis',
        icon: <BadgeIcon />,
        roles: 102,
      },
      {
        path: '/permissions',
        label: 'Permissionamento',
        icon: <WorkIcon />,
        roles: 101,
      },
    ],
  },
  {
    id: 'dashboard-samsung',
    label: 'Dashboard Samsung',
    icon: <AssessmentIcon />,
    roles: 22,
    items: [
      {
        path: '/upload-image',
        label: 'Imagem',
        icon: <GroupAddIcon />,
        roles: 350,
      },
      {
        path: '/view-dashboard',
        label: 'Visualizar Imagem',
        icon: <GroupAddIcon />,
        roles: 351,
      },
    ],
  },
  {
    id: 'stock',
    label: 'Estoque',
    icon: <FaBoxOpen size='18' />,
    roles: 4,
    items: [
      {
        path: '/devolution-invoice',
        label: 'Emitir nota de devolução de peças',
        icon: <AssignmentReturnIcon size='18' />,
        roles: 103,
      },

      {
        path: '/devolution-invoiceUnclass',
        label: 'Emitir nota UNCLASS',
        icon: <AssignmentReturnIcon size='18' />,
        roles: 104,
      },
    ],
  },
  {
    id: 'financial',
    label: 'Financeiro',
    icon: <SavingsIcon size='18' />,
    roles: 18,
    items: [
      {
        path: '/financial-close',
        label: 'Fechamento financeiro',
        icon: <PaymentsIcon size='18' />,
        roles: 107,
      },
    ],
  },
  {
    id: 'gspn',
    label: 'GSPN',
    icon: <PrecisionManufacturingIcon size='18' />,
    roles: 17,
    items: [
      {
        path: '/change-status-gspn',
        label: 'Atualizar status no GSPN',
        icon: <BuildIcon />,
        roles: 105,
      },
      {
        path: '/delete-parts-gspn',
        label: 'Deletar peças lançadas no GSPN',
        icon: <DeleteForeverIcon />,
        roles: 106,
      },
    ],
  },
  {
    id: 'releases',
    label: 'Lançamentos',
    icon: <PostAddIcon />,
    roles: 21,
    items: [
      {
        path: '/block-os',
        label: 'Bloqueio de OS',
        icon: <LockOpenIcon />,
        roles: 300,
      },
    ],
  },
  {
    id: 'invoices',
    label: 'Notas Fiscais',
    icon: <ReceiptLongIcon size='18' />,
    roles: 19,
    items: [
      {
        path: '/search-invoices',
        label: 'Consultar notas fiscais',
        icon: <FindInPageIcon />,
        roles: 109,
      },
      {
        path: '/new-kit',
        label: 'Kit novo',
        icon: <PhoneAndroidIcon />,
        roles: 108,
      },

      {
        path: '/devolution-invoiceUnclass',
        label: 'Emitir nota U-CLASS ',
        icon: <AssignmentReturnIcon size='18' />,
        roles: 108,
      },
    ],
  },
  {
    id: 'receivement',
    label: 'Recebimento',
    icon: <GiMailbox />,
    roles: 12,
    items: [
      {
        path: '/prodtucts/conference',
        label: 'Conferência de produtos',
        icon: <CategoryIcon />,
        roles: 112,
      },
      {
        path: '/products',
        label: 'Recebimento de produtos',
        icon: <ProductionQuantityLimitsIcon />,
        roles: 111,
      },
    ],
  },
  {
    id: 'service-order',
    label: 'Ordem de Serviço',
    icon: <FaFileAlt />,
    roles: 9,
    items: [{}],
  },
  {
    id: 'reports',
    label: 'Relatórios',
    icon: <AssignmentReturnIcon />,
    roles: 15,
    items: [
      {
        path: '/open-service-order',
        label: 'Relatório Abertura de OS',
        icon: <SummarizeIcon />,
        roles: 200,
      },
      {
        path: '/production-by-sector',
        label: 'Produção Por Setor',
        icon: <SummarizeIcon />,
        roles: 201,
      },
      {
        path: '/objects-received',
        label: 'Relatório Objetos Recebidos',
        icon: <SummarizeIcon />,
        roles: 202,
      },
      {
        path: '/checkin-production',
        label: 'Relatório Produção Checkin',
        icon: <SummarizeIcon />,
        roles: 203,
      },
      {
        path: '/production-by-technician',
        label: 'Relatório Produção Por Técnico',
        icon: <SummarizeIcon />,
        roles: 204,
      },
      {
        path: '/quality-control',
        label: 'Relatório Controle de Qualidade',
        icon: <SummarizeIcon />,
        roles: 205,
      },
      {
        path: '/os-opening-errors',
        label: 'Relatório Erros Abertura de OS',
        icon: <SummarizeIcon />,
        roles: 206,
      },
      {
        path: '/aging-with-parts',
        label: 'Relatório Aging com Peças',
        icon: <SummarizeIcon />,
        roles: 207,
      },
      {
        path: '/general-budgets',
        label: 'Relatório Orçamentos Geral',
        icon: <SummarizeIcon />,
        roles: 208,
      },
      {
        path: '/in-out',
        label: 'Relatório IN OUT',
        icon: <SummarizeIcon />,
        roles: 209,
      },
      {
        path: '/aging-fg',
        label: 'Relatório Aging FG',
        icon: <SummarizeIcon />,
        roles: 210,
      },
      {
        path: '/aging',
        label: 'Relatório Aging',
        icon: <SummarizeIcon />,
        roles: 211,
      },
      {
        path: '/samsung-budget-report',
        label: 'Relatório Orçamentos Samsung',
        icon: <SummarizeIcon />,
        roles: 212,
      },
      {
        path: '/resubmission-cases-open',
        label: 'Casos de reenvio - Aberto',
        icon: <SummarizeIcon />,
        roles: 213,
      },
      {
        path: '/resubmission-cases-updated',
        label: 'Casos de reenvio - Atualizado',
        icon: <SummarizeIcon />,
        roles: 214,
      },
      {
        path: '/aging-4hrs',
        label: 'Relatório Aging 4Hrs',
        icon: <SummarizeIcon />,
        roles: 215,
      },
      {
        path: '/os-assignment',
        label: 'Relatório Atribuição OS',
        icon: <SummarizeIcon />,
        roles: 216,
      },
      {
        path: '/billing',
        label: 'Relatório Billing',
        icon: <SummarizeIcon />,
        roles: 217,
      },
      {
        path: '/parts-x-models',
        label: 'Relatório Peças x Modelos (BOR)',
        icon: <SummarizeIcon />,
        roles: 218,
      },
      {
        path: '/component-registration',
        label: 'Relatório Cadastro Componentes',
        icon: <SummarizeIcon />,
        roles: 219,
      },
      {
        path: '/out-of-warranty',
        label: 'Relatório Fora de Garantia',
        icon: <SummarizeIcon />,
        roles: 220,
      },
      {
        path: '/parts',
        label: 'Relatório de Peças',
        icon: <SummarizeIcon />,
        roles: 221,
      },
      {
        path: '/parts-bpo',
        label: 'Relatório de Peças BPO',
        icon: <SummarizeIcon />,
        roles: 222,
      },
      {
        path: '/parts-bpo-total',
        label: 'Relatório de Peças BPO Total',
        icon: <SummarizeIcon />,
        roles: 223,
      },
      {
        path: '/cancellation-of-chargebacks',
        label: 'Relatório de Cancelamento de Estornos',
        icon: <SummarizeIcon />,
        roles: 224,
      },
      {
        path: '/cancellation-QA',
        label: 'Relatório de Cancelamento QA',
        icon: <SummarizeIcon />,
        roles: 225,
      },
      {
        path: '/pn-query-archived',
        label: 'Relatório de Consulta PN Arquivei',
        icon: <SummarizeIcon />,
        roles: 226,
      },
      {
        path: '/query-voc',
        label: 'Relatório de Consulta VOC',
        icon: <SummarizeIcon />,
        roles: 227,
      },
      {
        path: '/parts-consumption',
        label: 'Relatório de Consumo de Peças',
        icon: <SummarizeIcon />,
        roles: 228,
      },
      {
        path: '/os-reported',
        label: 'Relatório de OS Reportadas',
        icon: <SummarizeIcon />,
        roles: 229,
      },
      {
        path: '/bor-without-pn',
        label: 'Relatório de BOR sem PN',
        icon: <SummarizeIcon />,
        roles: 230,
      },
      {
        path: '/technical-stock',
        label: 'Relatório de Estoque Técnico',
        icon: <SummarizeIcon />,
        roles: 231,
      },
      // {
      //   path: '/historic-stock-position',
      //   label: 'Relatório Historico Posição de Estoque',
      //   icon: <SummarizeIcon />,
      //   roles: 232,
      // },
      {
        path: '/stock-position',
        label: 'Relatório Posição de Estoque',
        icon: <SummarizeIcon />,
        roles: 233,
      },
      {
        path: '/position-entry-notes',
        label: 'Relatório Posição Notas de Entrada',
        icon: <SummarizeIcon />,
        roles: 234,
      },
      {
        path: '/imei-query',
        label: 'Relatório de Consulta IMEI',
        icon: <SummarizeIcon />,
        roles: 235,
      },
      {
        path: '/aging-pll-with-parts',
        label: 'Relatório de Aging PLL com Peças',
        icon: <SummarizeIcon />,
        roles: 236,
      },
      {
        path: '/in-out-pll',
        label: 'Relatório IN OUT PLL',
        icon: <SummarizeIcon />,
        roles: 237,
      },
      {
        path: '/parts-pll',
        label: 'Relatório de Peças PLL',
        icon: <SummarizeIcon />,
        roles: 238,
      },
      {
        path: '/fg-consumed-parts',
        label: 'Relatório de Peças Consumidas FG',
        icon: <SummarizeIcon />,
        roles: 239,
      },
      {
        path: '/delivery-confirmation',
        label: 'Relatório de Confirmação de Entrega',
        icon: <SummarizeIcon />,
        roles: 240,
      },
      {
        path: '/non-compliance',
        label: 'Relatório Não Conformidade',
        icon: <SummarizeIcon />,
        roles: 241,
      },
      {
        path: '/receiving-parts',
        label: 'Relatório Recebimento de Peças',
        icon: <SummarizeIcon />,
        roles: 242,
      },
      {
        path: '/summary-cmp',
        label: 'Relatório Resumo Cmp',
        icon: <SummarizeIcon />,
        roles: 243,
      },
      {
        path: '/unlock-history',
        label: 'Relatório Histórico de Desbloqueio',
        icon: <SummarizeIcon />,
        roles: 244,
      },
    ],
  },
  {
    id: 'sac',
    label: 'SAC',
    icon: <LocalPhoneIcon />,
    roles: 13,
    items: [
      {
        path: '/graphic/budget',
        label: 'Gráfico orçamentos',
        icon: <AssessmentIcon />,
        roles: 108,
      },
    ],
  },
];
