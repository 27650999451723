import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';
import * as Yup from 'yup';

const typeSelect = [
  { label: 'Detalhado', value: 'detalhado' },
  { label: 'Resumido', value: 'resumido' },
];

const typeProcess = [
  { label: 'Todos', value: 0 },
  { label: 'Refurbish', value: 1 },
  { label: 'Sem Refurbish', value: 2 },
];

const validationSchema = Yup.object({
  type_report: Yup.string().required('Tipo do relatório é obrigatório'),
  type_process: Yup.number().required('Tipo de Processo é obrigatório'),
});

export const OpenServiceOrderReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    try {
      const { data } = await providersService.getOpenServiceOrderReport({
        type_report: values.type_report,
        type_process: values.type_process,
        input_data: values.input_data,
        output_data: values.output_data,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { OpenServiceOrderReport: worksheet },
          SheetNames: ['OpenServiceOrderReport'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Abertura_OS_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Relatório Abertura de OS</PageTitle>

      <Formik
        initialValues={{
          type_report: '',
          type_process: '',
          input_data: new Date(),
          output_data: new Date(),
        }}
        validationSchema={validationSchema}
        onSubmit={handleDownloadReport}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='type_report'
                label='Tipo do relatório'
                variant='outlined'
                options={typeSelect}
                component={Select}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='type_process'
                label='Tipo de Processo'
                variant='outlined'
                options={typeProcess}
                component={Select}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='input_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data inicial'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                disabled={isLoading}
                name='output_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data final'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Button type='submit' fullWidth loading={isLoading}>
                Exportar relatório
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
