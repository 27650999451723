import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Box from '@mui/material/Box';
import { Button, DatePicker, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';
import * as Yup from 'yup';

const typeSelect = [
  { label: 'Detalhado', value: 'detalhado' },
  { label: 'Resumido', value: 'resumido' },
];

const typeProduct = [
  { label: 'Notebook', value: 15 },
  { label: 'Celular', value: 2 },
  { label: 'Tablet', value: 5 },
];

const columns = [
  { label: 'Trasf' },
  { label: 'Numeros' },
  { label: 'Dt. Log' },
  { label: 'Nome' },
  { label: 'Ordem' },
  { label: 'Motivo' },
  { label: 'Aging' },
  { label: 'Tag' },
  { label: 'Turno' },
  { label: 'bpo' },
  { label: 'subBpo' },
];

const columns2 = [{ label: 'Dt. Log' }, { label: 'Trasnf' }, { label: 'Qtd' }, { label: 'Ordem' }];

const validationSchema = Yup.object({
  type_report: Yup.string().required('Tipo do relatório é obrigatório'),
});

export const ProductionBySector = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataReport, setDataReport] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [typeReport, setTypeReport] = useState('');

  const handleDownloadReport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Relatório está sendo gerado');
    setTypeReport(values.type_report);
    try {
      const { data } = await providersService.getProductionBySector({
        type_report: values.type_report,
        type_product: values.type_product,
        input_data: values.input_data,
        output_data: values.output_data,
      });

      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets: { ProductionBySector: worksheet },
          SheetNames: ['ProductionBySector'],
        };

        const now = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');
        XLSX.writeFile(workbook, `Relatorio_Produto_Por_Setor_${now}.xlsx`);
        toast.success('Relatório gerado com sucesso!');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      console.error('Erro ao gerar o relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateTable = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde um momento, Tabela está sendo gerada');
    setTypeReport(values.type_report);
    try {
      const { data } = await providersService.getProductionBySector({
        type_report: values.type_report,
        type_product: values.type_product,
        input_data: values.input_data,
        output_data: values.output_data,
      });
      setDataReport(data);
    } catch (error) {
      console.error('Erro ao gerar a tabela.');
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <PageTitle>Relatório Produção Por Setor</PageTitle>

      <Formik
        initialValues={{
          type_report: '',
          type_product: [],
          input_data: new Date(),
          output_data: new Date(),
        }}
        validationSchema={validationSchema}
        onSubmit={handleDownloadReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_report'
                  label='Tipo do relatório'
                  variant='outlined'
                  options={typeSelect}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='type_product'
                  label='Tipo de Pruduto'
                  variant='outlined'
                  options={typeProduct}
                  component={Select}
                  multiple
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Exportar relatório
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  color='secondary'
                  onClick={() => handleGenerateTable(props.values)}
                  fullWidth
                  loading={isLoading}
                >
                  Gerar Tabela
                </Button>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid container>
                <Grid xs={12} md={12} lg={12}>
                  <Table
                    name='Table'
                    headers={typeReport === 'detalhado' ? columns : columns2}
                    maxHeight
                    disableNumeration
                  >
                    {dataReport
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          {Object.keys(item).map((key, columnIndex) => (
                            <TableCell key={columnIndex}>{item[key]}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </Table>
                  <TablePagination
                    component='div'
                    count={dataReport.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Itens por página'
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
