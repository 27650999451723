import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as yup from 'yup';

import { releasesService } from '../../../../../services/releases';

const validationSchema = yup.object().shape({
  type_blocks: yup.string().required('Tipo bloqueio é obrigatório'),
  value_block: yup.string().required('Valor do bloqueio é obrigatório'),
  input_obs: yup.string().required('Observação é obrigatório'),
});

export const DialogBlock = ({ openDialogBlock, handleCloseDialogBlock }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTypeBlock, setDataTypeBlock] = useState([{ label: '', value: '' }]);
  const user = useContextSelector(AuthContext, (context) => context.user);

  const getTypeBlocks = useCallback(async () => {
    try {
      const { data } = await releasesService.getTypeBlock();
      setDataTypeBlock(data);
    } catch (error) {
      console.error('Erro ao obter os tipos de bloqueios: ' + error.message);
    }
  }, []);

  useEffect(() => {
    getTypeBlocks();
  }, [getTypeBlocks]);

  const insertBlock = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await releasesService.getValueBlock({
        idTipoBloqueio: values.type_blocks,
        idValor: values.value_block,
        obs: values.input_obs,
        usuario: user.name,
      });
      console.log(data.status);
      if (data.status === 500) {
        toast.error(data.msg);
      } else {
        toast.success(data.msg);
      }
    } catch (error) {
      toast.error('Erro ao inserir bloqueio');
    } finally {
      setIsLoading(false);
      await handleCloseDialogBlock();
    }
  };

  return (
    <>
      <Dialog open={openDialogBlock}>
        <Dialog.Title onClose={handleCloseDialogBlock}>Bloqueio de OS</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              value_block: '',
              type_blocks: '',
              input_obs: '',
            }}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form>
                <Grid container spacing={3} justifyContent='center'>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      disabled={isLoading}
                      name='type_blocks'
                      label='Tipo Bloqueio'
                      variant='outlined'
                      options={dataTypeBlock}
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      name='value_block'
                      label='Valor de bloqueio'
                      variant='outlined'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      name='input_obs'
                      label='Observação'
                      variant='outlined'
                      component={Text}
                      multiline
                      minRows={8}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button
                      fullWidth
                      color='secondary'
                      loading={isLoading}
                      onClick={() => insertBlock(values)}
                    >
                      Bloquear
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button fullWidth onClick={handleCloseDialogBlock}>
                      Fechar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
