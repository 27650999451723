import { useRouteMatch } from 'react-router';

import { DevolutionInvoiceUnclass } from 'pages/Private/Gspn/DevolutionInvoiceUnclass';
import { NewKit } from 'pages/Private/Invoices/NewKit';
import { SearchInvoices } from 'pages/Private/Invoices/SearchInvoices';

import { Route } from '../Route';

export const InvoicesRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/new-kit`} component={NewKit} />
      <Route isPrivate path={`${url}/search-invoices`} component={SearchInvoices} />
      <Route
        isPrivate
        path={`${url}/devolution-invoiceUnclass`}
        component={DevolutionInvoiceUnclass}
      />
    </>
  );
};
