import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as UploadImg } from 'assets/images/testeImageUpload.svg';
import { Button, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import XLSX from 'xlsx';
import * as yup from 'yup';

const data = XLSX.utils.json_to_sheet(
  [
    { S: 1, h: 2, e: 3, e_1: 4, t: 5, J: 6, S_1: 7 },
    { S: 2, h: 3, e: 4, e_1: 5, t: 6, J: 7, S_1: 8 },
  ],
  { skipHeader: 1 },
);

export const validationSchema = yup.object().shape({
  operation: yup.string().required(),
  shipping_number: yup.string().required(),
  volume: yup.string().required(),
});

const operationOptions = [
  {
    value: 'REMESSA PARA ANALISE SEM RETORNO',
    label: 'REMESSA PARA ANALISE SEM RETORNO',
  },
  // {
  //   value: 'DEVOLUCAO DE COMPRA DE MATERIAL DE USO OU CONSUMO',
  //   label: 'DEVOLUCAO DE COMPRA DE MATERIAL DE USO OU CONSUMO',
  // },
  // {
  //   value: 'DEVOLUCAO DE MERCADORIA DESTINADA AO USO CONSUMO SUJEITO AO REGIME ST',
  //   label: 'DEVOLUCAO DE MERCADORIA DESTINADA AO USO CONSUMO SUJEITO AO REGIME ST',
  // },
  // {
  //   value: 'DEVOLUCAO DE MERCADORIA RECEBIDA EM TRANSFERENCIA PARA COMERCIALIZACAO',
  //   label: 'DEVOLUCAO DE MERCADORIA RECEBIDA EM TRANSFERENCIA PARA COMERCIALIZACAO',
  // },
];

export const DevolutionInvoiceUnclass = () => {
  const [importing, setImporting] = useState(false);

  const inputFileRef = useRef(null);
  const formikRef = useRef();

  const handleDownloadLayoutDevolutionInvoices = async () => {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(
      [
        {
          a: 'CÓDIGO',
          b: 'DESCRIÇÃO',
          c: 'NCM',
          e: 'QTD',
        },
      ],
      {
        skipHeader: 1,
      },
    );

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'LayoutDevolutionInvoicesUnclass.xlsx',
    );
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = async (e) => {
    setImporting(true);
    try {
      const file = e.target.files[0];

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const getDevolutions = arr.map(function (obj) {
          if (
            obj.split(';')[0] == null ||
            obj.split(';')[0] == undefined ||
            obj.split(';')[0] == ''
          ) {
            return false;
          } else {
            const dados = {
              code: obj.split(';')[0],
              description: obj.split(';')[1],
              ncm: obj.split(';')[2],
              qtd: obj.split(';')[3],
              operation: formikRef.current?.values?.operation,
              shipping_number: formikRef.current?.values?.shipping_number,
              volume: formikRef.current?.values?.volume,
            };

            return dados;
          }
        });

        const filterDevolutions = getDevolutions.filter((item) => item !== false);

        try {
          const { data } = await componentService.createDevolutionInvoiceUnclass({
            devolutions: filterDevolutions,
          });

          if (data.length != 0) {
            toast.success(`${data.length} services importadas com sucesso`);
            setImporting(false);
            window.location.reload();
          } else {
            toast.error('Ocorreu um erro ao realizar a importação das services');
            setImporting(false);
          }
        } catch (error) {
          toast.error(error?.response?.data?.message);
        } finally {
          setImporting(false);
        }
      };

      reader.readAsText(file);

      setImporting(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setImporting(false);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Emitir nota de devolução de peças U-CLASS</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{ operation: '', shipping_number: '', volume: '' }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Paper variant='elevation'>
                    <Grid container spacing={2} style={{ marginLeft: 0.1 }}>
                      <Grid item xs={12} lg={3}>
                        <Button
                          fullWidth
                          onClick={handleImportClick}
                          type=''
                          disabled={importing || !props.isValid || !props.dirty}
                          startIcon={<FileUploadIcon />}
                        >
                          IMPORTAR PLANILHA
                        </Button>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Button
                          fullWidth
                          variant='outlined'
                          startIcon={<DownloadIcon />}
                          onClick={handleDownloadLayoutDevolutionInvoices}
                          disabled={importing}
                        >
                          BAIXAR PLANILHA
                        </Button>
                      </Grid>

                      {importing ? (
                        <Grid xs={6} lg={12}>
                          <Box sx={{ width: '90%' }}>
                            <LinearProgress color='secondary' />
                          </Box>
                        </Grid>
                      ) : (
                        <Grid xs={6} lg={12} style={{ display: 'flex', margin: 'auto' }}>
                          <UploadImg style={{ height: 200, marginTop: 15 }} />
                        </Grid>
                      )}

                      <Grid item xs={12} lg={6}>
                        <Field
                          name='operation'
                          label='Tipo de Operação'
                          variant='outlined'
                          size='small'
                          component={Select}
                          disabled={importing}
                          options={operationOptions}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Field
                          name='shipping_number'
                          label='Embarque'
                          variant='outlined'
                          size='small'
                          component={Text}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Field
                          name='volume'
                          label='Volume'
                          variant='outlined'
                          size='small'
                          component={Text}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
